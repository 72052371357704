body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "DMSans-Regular";
  src: local("DMSans-Regular"),
    url(./font/DMSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "DMSans-Italic";
  src: local("DMSans-Italic"), url(./font/DMSans-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "DMSans-Medium";
  src: local("DMSans-Medium"), url(./font/DMSans-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "DMSans-Bold";
  src: local("DMSans-Bold"), url(./font/DMSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Inter-Regular";
  src: local("Inter-Regular"), url(./font/Inter-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Inter-Medium";
  src: local("Inter-Medium"), url(./font/Inter-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Inter-SemiBold";
  src: local("Inter-SemiBold"),
    url(./font/Inter-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Inter-Bold";
  src: local("Inter-Bold"), url(./font/Inter-Bold.ttf) format("truetype");
}

html,
body {
  scroll-behavior: smooth;
}

.textEllipsis p {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.textEllipsis p:hover {
  text-overflow: clip;
  white-space: normal;
  -webkit-line-clamp: initial;
  cursor: pointer;
}
